import {
  Typography,
  Drawer,
  Container,
  Grid,
  Button,
  Box,
} from "@mui/material";
import GrabItem from "../../../components/GrabItem";

import { useNavigate } from "react-router-dom";

import cell from "../../../assets/icons/Icone/fi-rr-smartphone.png";
import email from "../../../assets/icons/Icone/fi-rr-at.png";
import map from "../../../assets/icons/Icone/fi-rr-marker.png";
import user from "../../../assets/icons/Icone/fi-rr-user.png";
import company from "../../../assets/icons/Icone/fi-rr-building.png";
import role from "../../../assets/icons/Icone/fi-rr-briefcase.png";
import meravigliaLogoChiara from "../../../assets/img/scritta meraviglia chiara.png";
import Meraviglia from "../../../components/Meraviglia";
import { useSelector } from "react-redux";
import { translations as t } from "../../../assets/translations.js";
import styles from "../css/ContactDrawer.module.css";

export default function ContactDrawer({
  username,
  contactData,
  downloadContact,
  drawerOpen,
  setDrawerOpen,
}) {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.userLang.value);
  const isAuth = useSelector((state) => state.userAuth.isAuth);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      classes={{ paper: styles.drawerPaper }}
    >
      <GrabItem setDrawerOpen={setDrawerOpen} />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 2,
          marginBottom: 2,
          width: "100%",
        }}
      >
        <div className={styles.divText}>
          <img src={user} alt="..." className={styles.icons} />
          <Typography variant="h6" className={styles.contactInfo}>
            {contactData.name + " " + contactData.surname}
          </Typography>{" "}
        </div>
        <div className={styles.divText}>
          {/* <img src={user} alt="..." className={styles.icons} /> */}
          <Typography variant="subtitle2" className={styles.contactInfo}>
            <i>hellolink.pro/{username}</i>
          </Typography>{" "}
        </div>
        {contactData.business_name && (
          <div className={styles.divText}>
            <img src={company} alt="..." className={styles.icons} />
            <Typography variant="body1" className={styles.contactInfo}>
              {contactData.business_name}
            </Typography>{" "}
          </div>
        )}
        {contactData.role && (
          <div className={styles.divText}>
            <img src={role} alt="..." className={styles.icons} />
            <Typography variant="body1" className={styles.contactInfo}>
              {contactData.role}
            </Typography>{" "}
          </div>
        )}
        <Box sx={{ width: "90%" }}>
          {contactData.phone_numbers
            ? contactData.phone_numbers.map((item, index) => {
                return (
                  <div key={`phone-${index}`} className={styles.divInfoText}>
                    <img src={cell} alt="..." className={styles.icons} />
                    <Typography variant="body1" className={styles.contactInfo}>
                      {item.phonenumber}
                    </Typography>
                  </div>
                );
              })
            : null}
          {contactData.emails
            ? contactData.emails.map((item, index) => {
                return (
                  <div key={`email-${index}`} className={styles.divInfoText}>
                    <img src={email} alt="..." className={styles.icons} />
                    <Typography variant="body1" className={styles.contactInfo}>
                      {item.email}
                    </Typography>
                  </div>
                );
              })
            : null}
          {contactData.addresses
            ? contactData.addresses.map((item, index) => {
                // Costruisci un array con tutte le parti dell'indirizzo che vuoi visualizzare
                const addressParts = [
                  item.address_line1,
                  item.address_line2,
                  item.house_number,
                  item.place,
                  item.zip_code,
                  item.city ? item.city.name : null,
                  item.province ? item.province.name : null,
                  item.country ? item.country.name : null,
                ];

                // Filtra le parti dell'indirizzo che non sono né null né stringhe vuote
                const filteredAddressParts = addressParts.filter(
                  (part) => part
                );

                // Unisci le parti rimanenti con una virgola e uno spazio
                const formattedAddress = filteredAddressParts.join(", ");

                // Restituisci il componente Typography solo se c'è almeno una parte dell'indirizzo
                return (
                  formattedAddress && (
                    <div
                      key={`address-${index}`}
                      className={styles.divInfoText}
                    >
                      <img src={map} alt="..." className={styles.icons} />
                      <Typography
                        variant="body1"
                        className={styles.contactInfo}
                      >
                        {formattedAddress}
                      </Typography>
                    </div>
                  )
                );
              })
            : null}
          {contactData.vat_number && (
            <div className={styles.divInfoText}>
              <img src={role} alt="..." className={styles.icons} />
              <Typography variant="body1" className={styles.contactInfo}>
                {t["contact-iva"][lang]}: {contactData.vat_number}
              </Typography>{" "}
            </div>
          )}
          {contactData.sdi && (
            <div className={styles.divInfoText}>
              <img src={role} alt="..." className={styles.icons} />
              <Typography variant="body1" className={styles.contactInfo}>
                {t["contact-sdi"][lang]}: {contactData.sdi}
              </Typography>{" "}
            </div>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          className={styles.buttonForm}
          onClick={downloadContact}
        >
          Salva Contatto
        </Button>
      </Container>
      <Box className={styles.grabItemBox}>
        {isAuth ? (
          <Box className={styles.poweredByBox}>
            <Typography variant="body2" sx={{ color: "#8E8E8E" }}>
              Powered by
            </Typography>
            <img
              src={meravigliaLogoChiara}
              alt="Meraviglia Logo"
              style={{ marginLeft: "8px", height: "50px", width: "auto" }}
            />
          </Box>
        ) : (
          <Box className={styles.poweredByBox}>
            <Typography variant="h5" sx={{ color: "white", mt: 2 }}>
              {t["botdrw-meralink"][lang]}
            </Typography>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/signup")}
                  sx={{
                    width: { xs: "150px", sm: "190px" },
                    borderRadius: 10,
                    color: "white",
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  {t["botdrw-registrati"][lang]}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    window.open("https://amzn.eu/d/9DBd4QI", "_blank")
                  }
                  sx={{
                    width: { xs: "150px", sm: "190px" },
                    borderRadius: 10,
                    color: "white",
                    fontSize: { xs: 12, sm: 16 },
                  }}
                >
                  {t["botdrw-carta"][lang]}
                </Button>
              </Grid>
            </Grid>
            <Meraviglia chiara={true} />
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
