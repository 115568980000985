// TODO: This component will be deleted when the icon bank will change to SVG icons
import React, { useState, useEffect, useRef } from "react";

const ProcessedImage = ({ source, alt, style, targetColor }) => {
  const [processedIcon, setProcessedIcon] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!source || !targetColor) {
      setProcessedIcon(source);
      return;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      changeImageColor(context, canvas.width, canvas.height, targetColor);
      setProcessedIcon(canvas.toDataURL());
    };

    image.src = source;
    image.crossOrigin = "anonymous"; // Added crossOrigin attribute
  }, [source, targetColor]);

  const changeImageColor = (context, width, height, targetColor) => {
    const imageData = context.getImageData(0, 0, width, height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      // Replace the color with the target color
      data[i] = targetColor.r; // Red
      data[i + 1] = targetColor.g; // Green
      data[i + 2] = targetColor.b; // Blue
      // data[i + 3] is the alpha component, we leave it as is
    }

    context.putImageData(imageData, 0, 0);
  };

  return (
    <>
      <img src={processedIcon || source} alt={alt} style={style} />
      {/* Hidden canvas for processing the image */}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </>
  );
};

export default ProcessedImage;
